const debug = true;

//----------- Control some dynamic loading elements -------------//

// Init BhgComponents dynamically
import BhgComponents from "bhg-components";
import BarceloComponents from "barcelo-components-init";

document.addEventListener("DOMContentLoaded", async () => {
  // Add loader for dynamic loading elements

  document.querySelectorAll(".dynamicLoading.hiddenComponent").forEach((element) => {
    const loader = document.createElement("div");
    loader.classList.add("component-loader");
    loader.innerHTML = `<div class="loader-spinner"></div>`;

    element.appendChild(loader);
  });

  await BarceloComponents.init({ ...BhgComponents });
});

// Hide footer until it's fully loaded
const footer = document.querySelector("footer");
if (footer) {
  footer.classList.add("dynamicLoading", "hiddenComponent");
}

// Load RTL on some cases only
const detectedLanguages = ["ar-AE"];

const checkLanguage = () => {
  const currentLang = document.documentElement.lang;
  if (detectedLanguages.includes(currentLang)) {
    // console.log(`The language has changed to: ${currentLang}`);
    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-rtl.css");
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    observer.disconnect();
  }
};

const observer = new MutationObserver((mutationsList) => {
  for (const mutation of mutationsList) {
    if (mutation.type === "attributes" && mutation.attributeName === "lang") {
      checkLanguage();
    }
  }
});

observer.observe(document.documentElement, { attributes: true });
checkLanguage();

//----------- Control some dynamic loading elements -------------//

import "../../base/js/init.js";
import "../../base/js/utils/preCommonUtils.js";
import "../../../components/bhg/image.js";
import "../../base/js/utils/ajaxUtils.js";
import "../../base/js/utils/commonUtils.js";
import "../../base/js/shared/loading.js";
import "../../base/js/utils/customContextHubUtils.js";
import "../../base/js/utils/lastjs.js";

// Fastbooking
import "../../../components/barcelo/structure/fastbooking/js/commonFastbookingProps.js";
import "../../../components/barcelo/structure/fastbooking/js/c-fastbooking";

const loadNecesaryScripts = async function () {
  // Analytics
  if (document.cookie.includes("webVitals=true;")) {
    await import("../../base/js/utils/webVitals.js");
    if (debug) {
      console.log("Performance: Web Vitals loaded");
    }
  }
};

// Dynamic imports
if (document.readyState !== "loading") {
  loadNecesaryScripts();
} else {
  document.addEventListener("DOMContentLoaded", loadNecesaryScripts, true);
}
