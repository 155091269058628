//--- Lazy loading support for old browsers ---//
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import loadingAttributePolyfill from "loading-attribute-polyfill";

const imageDebug = true;
const imageFormatSupport = {
  avif: false,
  webp: false,
};

const PIXEL_PRECISION = 100;
const MAX_WIDTH = window.location.hostname === "www.barcelo.com" ? 3800 : 2000;
const MAX_HEIGHT = 2000;

const RENDITIONS = {
  SQUARE: "square",
  LAND: "land",
  PORTRAIT: "port",
  VERTICAL: "vert",
  STRIPE: "stripe",
  DEFAULT: "optm",
};

let prerequisitesChecked = false;

//------------------------  Get best image dimension for device---------------------------//
const getOptimalDimension = (image, isWidth = true) => {
  let container = image;
  let dimension = isWidth ? container.clientWidth : container.clientHeight;

  while (dimension === 0 && container.parentNode) {
    container = container.parentNode;
    dimension = isWidth ? container.clientWidth : container.clientHeight;
  }

  const optimalDimension = Math.ceil((dimension * (bcl.u.mobile.isMobile() && devicePixelRatio >= 1.75 ? devicePixelRatio / 2 : devicePixelRatio)) / PIXEL_PRECISION) * PIXEL_PRECISION;
  const MAX_DIMENSION = isWidth ? MAX_WIDTH : MAX_HEIGHT;

  return optimalDimension === 0 ? PIXEL_PRECISION : Math.min(optimalDimension, MAX_DIMENSION);
};

//--------------------- Get image rendition type -------------------------//
const getImageRendition = (image) => {
  const closestElement = image.closest("[data-rendition-breakpoints]");
  if (!closestElement) {
    return RENDITIONS.DEFAULT;
  }

  const imageType = JSON.parse(closestElement.getAttribute("data-rendition-breakpoints"));
  const isMobile = bcl.u.mobile.isMobile();

  return isMobile ? Object.keys(imageType)[0] : Object.keys(imageType)[1] || Object.keys(imageType)[0];
};

//--------------------- Adapt image size to device without Dynamic media-------------------------//
const setOptimalDimensions = (image, optimalWidth) => {
  const newSrc = image.parentElement.dataset.cmpSrc;
  const imageFormat = getImageRendition(image);
  image.src = newSrc?.includes("bhgimg") ? newSrc.replace("bhgimg", `bhgimg.${imageFormat}${optimalWidth}`) : newSrc;
};

//--------------------- Detect if AVIF and WebP are supported ------------------------//
const canUseAvif = () => {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener("error", () => resolve(false));
    image.addEventListener("load", () => resolve(image.width === 1));
    image.src =
      "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUEAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAABYAAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAEAAAABAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgSAAAAAAABNjb2xybmNseAACAAIABoAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAB5tZGF0EgAKBzgADlAgIGkyCR/wAABAAACvcA==";
  }).catch(() => false);
};

const canUseWebp = () => {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener("error", () => resolve(false));
    image.addEventListener("load", () => resolve(image.width === 1));
    image.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
  }).catch(() => false);
};

//---------------------- Check URL exists ------------------------------//
const checkURL = async (url, returnData = false, format = "json") => {
  try {
    const response = await fetch(url);
    if (response.status === 200 && !response.url.includes("404")) {
      return returnData ? (format === "json" ? response.json() : response.text()) : true;
    }
  } catch (error) {
    if (imageDebug) {
      console.error(error);
    }
  }
  return false;
};

//---------------------- Sorts Search Parameters to avoid overrides --------------//
const sortSearchParams = (url) => {
  const firstParam = { key: Array.from(url.searchParams.keys())[0], value: url.searchParams.get(Array.from(url.searchParams.keys())[0]) };
  if (firstParam.key.startsWith("$") && firstParam.key.endsWith("$")) {
    url.searchParams.delete(firstParam.key);
    url.searchParams.set(firstParam.key, firstParam.value);
  }
};

//--------------------- Adapt image size to device with Dynamic media-------------------------//
const setOptimalDimensionsDM = async (image, optimalWidth, optimalHeight) => {
  window.bcl.s.performance.processedImages = window.bcl.s.performance.processedImages + 1 || 0;
  const position = window.bcl.s.performance.processedImages;

  const cmpSrc = image.parentElement?.dataset?.cmpSrc;
  if (!cmpSrc.includes("s7g10.scene7.com/is/image/")) {
    console.error("Error loading DM Image. URL not from Scene7: " + cmpSrc);
    return;
  }

  const newSrc = new URL(cmpSrc);
  if (newSrc.protocol === "http:") {
    newSrc.protocol = "https";
  }

  image.classList.add(`loadPosition-${position}`);
  const availableCropURL = new URL(newSrc);
  availableCropURL.searchParams.set("req", "exists,text");
  availableCropURL.searchParams.set("cache", "on");

  const skipRenditionAdjust = !!image.closest(".c-hotel-room-list__header");
  const renditionType = getImageRendition(image);
  let renditionToApply = null;

  if (!/\/(logo|icon)/.test(image.parentElement.dataset.asset.toLowerCase())) {
    switch (renditionType) {
      case RENDITIONS.SQUARE:
        if (!skipRenditionAdjust) {
          optimalWidth = optimalHeight = Math.max(optimalWidth, optimalHeight);
        }
        availableCropURL.pathname += ":Square";
        renditionToApply = ":Square";
        break;
      case RENDITIONS.PORTRAIT:
        if (!skipRenditionAdjust) {
          optimalHeight = optimalWidth * 2;
        }
        availableCropURL.pathname += ":Portrait";
        renditionToApply = ":Portrait";
        break;
      case RENDITIONS.VERTICAL:
        availableCropURL.pathname += ":Vertical";
        renditionToApply = ":Vertical";
        break;
      case RENDITIONS.LAND:
        if (!skipRenditionAdjust) {
          optimalHeight = optimalWidth / 2;
        }
        availableCropURL.pathname += ":Landscape";
        renditionToApply = ":Landscape";
        break;
      case RENDITIONS.STRIPE:
        if (!skipRenditionAdjust) {
          optimalHeight = optimalWidth / 4;
        }
        availableCropURL.pathname += ":Stripe";
        renditionToApply = ":Stripe";
        break;
      default:
        break;
    }

    const availableCrop = await checkURL(availableCropURL.href, true, "text");
    if (typeof availableCrop === "string" && renditionToApply && availableCrop.includes("catalogRecord.exists=1")) {
      newSrc.pathname += renditionToApply;
    }

    if (!newSrc.searchParams.has("wid") && optimalWidth) {
      image.setAttribute("width", optimalWidth);
      newSrc.searchParams.set("wid", optimalWidth);
    }

    if (!newSrc.searchParams.has("hei") && optimalHeight) {
      image.setAttribute("height", optimalHeight);
      newSrc.searchParams.set("hei", optimalHeight);
    }

    if ((newSrc.searchParams.has("wid") || newSrc.searchParams.has("hei")) && !newSrc.searchParams.has("fit")) {
      newSrc.searchParams.set("fit", "crop,1");
    }

    if (!newSrc.searchParams.has("qlt")) {
      newSrc.searchParams.set("qlt", bcl.u.mobile.isMobile() ? "50" : imageFormatSupport.avif ? "60" : "75");
    }
  } else {
    if (!newSrc.searchParams.has("qlt")) {
      newSrc.searchParams.set("qlt", "50");
    }
  }

  const format = imageFormatSupport.avif ? "avif-alpha" : imageFormatSupport.webp ? "webp-alpha" : newSrc.toString().includes(".png") ? "png8-alpha" : "pjpeg";
  newSrc.searchParams.set("fmt", format);

  newSrc.searchParams.set("cache", "validate,on,on");
  newSrc.searchParams.set("defaultImage", "default-dm");

  const device = bcl.u.mobile.isMobile() ? "mobile" : "desktop";
  const alignment = image.closest(`[data-${device}-alignment]`)?.dataset[`${device}Alignment`];

  if (alignment) {
    const [horizontal, vertical] = alignment.split("-").map((val) => (val === "left" ? -1 : val === "right" ? 1 : 0));
    newSrc.searchParams.set("align", [horizontal, vertical].join(","));
  }

  sortSearchParams(newSrc);

  document.querySelectorAll(`img.loadPosition-${position}`).forEach((img) => {
    img.src = decodeURIComponent(newSrc.toString());
  });
};

//--------------------- Detect images to optimize -------------------------//
const optimizeImage = (image) => {
  if (image.dataset.optimized === "true" || image.classList.contains("loadPosition")) {
    return;
  }

  image.dataset.optimized = "true";
  const optimalWidth = getOptimalDimension(image, true);
  const optimalHeight = getOptimalDimension(image, false);
  const isDM = image.parentElement.dataset.isDm === "true";

  isDM ? setOptimalDimensionsDM(image, optimalWidth, optimalHeight) : setOptimalDimensions(image, optimalWidth);
};

//--------------------- Convert visible images to eager loading -------------------------//
const convertVisibleImageToEagerAndOptimize = (image) => {
  image.loading = "eager";
  optimizeImage(image);
};

//--------------------- Check and optimize images on load -------------------------//
const checkOnLoadVisibleImages = () => {
  document.querySelectorAll("img[data-barcelo-image='true']:not([data-optimized='true'])").forEach(convertVisibleImageToEagerAndOptimize);

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.querySelectorAll) {
          node.querySelectorAll("img[data-barcelo-image='true']:not([data-optimized='true'])").forEach(convertVisibleImageToEagerAndOptimize);
        }
      });
    });
  });

  observer.observe(document, { childList: true, subtree: true });
};

//--------------------- Prerequisite check for format support -------------------------//
const checkPrerequisites = async () => {
  if (prerequisitesChecked) {
    return;
  }

  const [avifSupported, webpSupported] = await Promise.all([canUseAvif(), canUseWebp()]);
  imageFormatSupport.avif = avifSupported;
  imageFormatSupport.webp = webpSupported;
  prerequisitesChecked = true;
};

//--------------------- General image initialization -------------------------//
const initImages = async () => {
  window.bcl.s.performance = window.bcl.s.performance || {};

  await checkPrerequisites();

  if (document.readyState !== "loading") {
    checkOnLoadVisibleImages();
  } else {
    document.addEventListener("DOMContentLoaded", checkOnLoadVisibleImages, true);
  }
};

//--------------------- Initialize image processing -------------------------//
initImages();
